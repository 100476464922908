@import "../../sass/variables";
@import "../../sass/mixins";


.box{
    width: 100%;
    display: flex;
    flex-direction: row;

    @include respond(tab-port){
        margin-bottom: 2rem;
    }

    &_item{
        flex: 1;
        border: .25rem solid #aaa;
        border-radius: .5rem;
        height: 4rem;
        transition: border .25s ease;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: .5rem;
        cursor: pointer;

        &:not(:last-child){
            margin-right: .5rem;
        }

        &_color{
            width: 4rem;
            border-radius: .5rem;
            height: 100%;
            margin-right: 1rem;
        }

        &_text{
            font-size: 1.5rem;
            font-weight: 500;
        }
    }
}

.selected{
    border-color: $purple;
}