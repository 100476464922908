@import "../../sass/mixins";


.main {

    height: fit-content;
    background: #f2f2f9;
    padding: 2rem 32.5vw;

    &_heading {
        margin-bottom: .3rem;
    }

    @include respond(tab-port){
        padding: 0 !important;
    }

    &_column {
        justify-content: center;
        padding: 5rem 1.5rem;
        row-gap: 3rem;
        @include shadow();
        background: #fff;
        min-height: calc(100vh - 4rem);
        border-radius: .5rem;

        @include respond(tab-port){
            padding: 0 !important;
            min-height: 100vh;
            border-radius: 0;
        }
    }

    &_block {
        cursor: pointer;
        height: 4rem;
        width: 4rem;
        @include shadow();
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: .5rem;
    }
}