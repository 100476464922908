@import "../../sass/mixins";
@import "../../sass/variables";

.main{
    font-weight: 100;
    color: #114;
    font-weight: 800;
    font-size: 2.5rem;
    width: fit-content;
    text-align: left;
    position: relative;
    margin-bottom: 1rem;

    &::after{
        display: none;
        width: 8rem;
        content: "";
        position: absolute;
        left: 0;
        bottom: -.75rem;
        height: .5rem;
        background: $primary;
    
        @include respond(tab-port){
            bottom: -.2rem;
            height: .4rem;
        }
    }

    @include respond(tab-port){
        font-size: 2.25rem;
        line-height: 3.25rem;
    }
}

.link{
    flex-direction: row;
    width: clamp(100%, 100%, 100%);
    font-weight: 400;

    &_name{
        color: #555;
        font-weight: 600;
        font-size: 1.25rem;
        text-decoration-line: underline;
        text-transform: capitalize;
    }

    @include respond(tab-port){
        display: flex;
    }
}

.after{
    margin-right: .2rem;
    &::after{
        content: ' > ';
    }
}