@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./mixins";
@import "./variables";

html{
    font-size: 62.5%;
    scroll-behavior: smooth;
}

*{
    box-sizing: border-box;
    backface-visibility: hidden;
    font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
}

body {
    margin: 0;
    color: #303030;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}


        header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            background-color: #fff;
            border-bottom: 1px solid #eee;
        }
        header img {
            height: 40px;
        }
        nav ul {
            list-style: none;
            display: flex;
            gap: 20px;
        }
        nav ul li {
            display: inline;
        }
        nav ul li a {
            text-decoration: none;
            color: #333;
            font-weight: 500;
        }
        .btn {
            background-color: #FDB600;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            font-weight: 500;
            cursor: pointer;
        }
        .hero {
            text-align: center;
            padding: 50px 20px;
            background-color: #fff;
        }
        .hero h1 {
            font-size: 2.5rem;
            color: #4062FF;
            margin-bottom: 20px;
        }
        .hero p {
            font-size: 1.2rem;
            margin-bottom: 30px;
        }
        .hero .btn, .herobtn {
            background-color: #4062FF;
        }
        .app-icons {
            display: flex;
            justify-content: center;
            gap: 30px;
            margin: 60px 0;
        }
        .app-icons img {
            width: 90px;
        }
        .section {
            padding: 50px 20px;
            text-align: center;
        }
        .section h2 {
            font-size: 2rem;
            margin-bottom: 20px;
        }
        .section p {
            font-size: 1rem;
            margin-bottom: 30px;
            max-width: 600px;
            margin: auto;
        }
        .stars {
            display: flex;
            justify-content: center;
            gap: 10px;
        }
        .stars img {
            width: 250px;
        }
        /* New Section Below */
        .features-section {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            padding: 50px 20px;
            row-gap: 74px;
            flex-direction: column;
            background-color: #fff;
        }
        .feature-box {
            width: 70%;
            margin: 20px auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            min-width: 250px;
            text-align: left;
            column-gap: 200px;
            margin-bottom: 40px;
        }
        .feature-box img {
            width: 250px;
            margin-bottom: 20px;
        }
        .feature-box div h3 {
            font-size: 1.5rem;
            margin-bottom: 15px;
            font-weight: 700;
        }
        .feature-box p {
            font-size: 1rem;
            margin-bottom: 20px;
        }
        .feature-box .btn {
            background-color: #4062FF;
            color: white;
        }
        .footer {
            text-align: center;
            padding: 20px;
            background-color: #4062FF;
            color: white;
        }

        /* Additional Section - Your Work, Everywhere */
        .work-section {
            padding: 50px 20px;
            text-align: center;
            background-color: #003366;
            color: white;
        }
        .work-section h2 {
            font-size: 2.5rem;
            margin-bottom: 20px;
        }
        .work-section p {
            font-size: 1rem;
            margin-bottom: 30px;
        }
        .work-section .btn {
            background-color: #4062FF;
            color: white;
        }
        .data-section {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            padding: 150px 20px;
        }
        .data-section .text-box {
            width: 45%;
            min-width: 300px;
            text-align: left;
        }
        .data-section h3 {
            font-size: 2rem;
            margin-bottom: 20px;
        }
        .data-section p {
            font-size: 1rem;
            margin-bottom: 20px;
        }
        .data-section .data-icons {
            display: flex;
            justify-content: center;
            gap: 20px;
            margin-top: 20px;
        }
        .data-section img {
            width: 360px;
        }
        .clients-section {
            text-align: center;
            padding: 50px 20px;
            background-color: #fff;
        }
        .clients-section h3 {
            font-size: 1.5rem;
            margin-bottom: 20px;
        }
        .clients-section img {
            width: 100px;
            margin: 20px;
        }
        
        .hexagon-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 50px;
            gap: 20px;
            cursor: pointer;
            width: 100%;
        }
        .hexagon {
            width: 100%;
        }

        /* Testimonial Section */
        .testimonial-section {
            text-align: center;
            padding: 50px 20px;
        }
        .testimonial-section h2 {
            font-size: 2.2rem;
            color: #333;
            margin-bottom: 30px;
        }
        .testimonial-wrapper {
            display: flex;
            justify-content: center;
            gap: 20px;
        }
        .testimonial {
            background-color: #F0F8FF;
            padding: 20px;
            border-radius: 10px;
            width: 300px;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        }
        .testimonial img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-bottom: 10px;
        }
        .testimonial-text {
            font-size: 1rem;
            color: #333;
            margin-bottom: 15px;
        }
        .author {
            font-weight: 700;
            color: #555;
        }
        .company {
            font-size: 0.9rem;
            color: #777;
        }

        /* Footer Section */
        .footer {
            background-color: #003366;
            padding: 40px 20px;
            color: white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }
        .footer div {
            flex: 1 1 250px;
            padding: 10px;
        }
        .footer a {
            color: white;
            text-decoration: none;
            font-weight: 500;
        }
        .footer a:hover {
            text-decoration: underline;
        }
        .footer p {
            font-size: 0.8rem;
        }

        /* Responsive Design */
        @media (max-width: 768px) {
            .hexagon-wrapper {
                flex-direction: column;
                gap: 15px;
            }
            .testimonial-wrapper {
                flex-direction: column;
            }
            nav {
                display: none;
            }
            .features-section {
                flex-direction: column;
                width: 100%;
                row-gap: 0;
                gap: 0;
            }
            .feature-box {
                flex-direction: column;
                width: 100%;
                column-gap: 0;
                gap: 0;
            }
            .feature-box > div {
                flex-direction: column;
            }
        }

        /* Responsive Design */
        @media (max-width: 768px) {
            .hero h1 {
                font-size: 2rem;
            }
            .section h2 {
                font-size: 1.5rem;
            }
            .app-icons img {
                width: 50px;
            }
            .stars img {
                width: 100px;
            }
            .feature-box {
                width: 45%;
            }
            .data-section {
                flex-direction: column;
                align-items: center;
            }
            .data-section .text-box {
                width: 100%;
                text-align: center;
            }
        }
        @media (max-width: 480px) {
            .hero h1 {
                font-size: 1.8rem;
            }
            .hero p {
                font-size: 1rem;
            }
            .section h2 {
                font-size: 1.2rem;
            }
            .stars img {
                width: 80px;
            }
            .feature-box {
                width: 100%;
            }
            .data-section img {
                width: 50px;
            }
        }