@import "../../sass/mixins";
@import "../../sass/variables";


.main {

    height: fit-content;
    background: #f2f2f9;
    padding: 2rem 32.5vw;

    &_heading {
        margin-bottom: .3rem;
    }

    &_social {
        width: fit-content !important;
        column-gap: .5rem;
        align-items: center;
        margin: auto;
        cursor: pointer;
        font-size: 1.25rem;
        font-weight: 500;
        padding: 1rem 2.5rem;
        @include shadow();
        border-radius: 2rem;

        &_hint {
            font-size: 1rem;
            color: #666;
            font-weight: 400;
        }
    }

    &_skip {
        width: fit-content;
        padding: 0 3rem;
        font-weight: 600;
        height: 3.5rem;
        bottom: 1rem;
        background: transparent;
        color: rgba($border, .5);
        font-size: 1.45rem;
        
        &:hover {
            background: transparent;
            color: rgba($border, .5);
            font-size: 1.45rem;
                
        }
    }

    &_continue {
        width: fit-content;
        padding: 0 4rem;
    }

    @include respond(tab-port){
        padding: 0 !important;
    }

    &_column {
        // justify-content: center;

        padding: 5rem 1.5rem;
        row-gap: 3rem;
        @include shadow();
        background: #fff;
        min-height: calc(100vh - 4rem);
        border-radius: .5rem;

        @include respond(tab-port){
            padding: 7.5rem 0 !important;
            min-height: 100vh;
            border-radius: 0;
        }
    }

    &_block {
        cursor: pointer;
        height: 4rem;
        width: 4rem;
        @include shadow();
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: .5rem;
    }
}