@import "../../sass/variables";
@import "../../sass/mixins";

.form{
    width: 100%;
    display: flex;
    position: relative;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;

    &_heading{
        margin-left: 2rem;
    }

    &_skip {
        position: absolute;
        left: 1rem;
        width: fit-content;
        padding: 0 3rem;
        font-weight: 600;
        height: 3.5rem;
        bottom: 1rem;
        background: transparent;
        color: rgba($border, .5);
        font-size: 1.45rem;


        &:hover {
            background: transparent;
            border: .1rem solid rgba($border, .1);
            @include shadow();
        }
    }

    &_button{
        width: 32.5%;
        height: fit-content;
        display: flex;
        margin: 1rem auto;
        margin-right: 1rem;
        position: relative;
        justify-content: flex-end;
    
        &_loading{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &_main{
        width: 100%;
        display: flex;
        flex-direction: column;
    
        &_input{
            padding: 1rem;
        }
    
        &_button{
            background: $primary;
            width: 100%;
            text-transform: uppercase;
            font-weight: 600;
            border-radius: .25rem;
            height: 3.5rem;
            border: none;
            cursor: pointer;
            color: white;
            border: .1rem solid darken($border, 5%);
            font-size: 1.45rem;

            @include respond(tab-port){
                height: 4.5rem;
            }

            &:hover{
                border: .1rem solid darken(rgba($border, .5), 5%);
                background: rgb(21, 72, 182);
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            }
        }
    }
}

.inactive{
    border-color: $gray;
    cursor: not-allowed;
    background: $gray;

    &:hover{
        background: $gray;
        color: #fff;
        box-shadow: none;
    }
}