@import "../../sass/animations";
@import "../../sass/mixins";

.positive{
    background: #19a340 !important;
}

.main{
    right: calc(-10vw + 1rem);
    width: 25vw;
    top: 1rem;
    position: fixed;
    background: #f04;
    border-radius: .25rem;
    color: white;
    font-weight: 500;
    font-size: 1.45rem;
    padding: 1.25rem 3rem; 
    padding-left: 3.5rem;
    animation: fromTop .5s ease-in forwards;

    @include respond(tab-port){
        width: calc(100% - 2rem);
        transform: translate(-50%, 0);
        left: 50%;
        top: 0;
        padding: 1.25rem 3rem;
        padding-left: 3.5rem;
        font-size: 1.25rem;
        right: unset;
    }

    &_logo {
        position: absolute;
        height: 2rem;
        width: 2rem;
        left: .75rem;
        top: 50%;
        transform: translate(0, -50%);
        filter: invert(500%) brightness(150%);
        mix-blend-mode: screen;
        

    }

    &_close {
        position: absolute;
        height: 2rem;
        width: 2rem;
        right: 1rem;
        top: 50%;
        cursor: pointer;
        transform: translate(0, -50%);        
    }
}