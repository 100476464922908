@import "../../sass/variables";
@import "../../sass/animations";

.collison {
  position: relative;
  width: 10px;
  // z-index: 1;
  height: 10px;
  border-radius: 5px;
  background-color: $purple;
  color: $purple;
}

.collison::before, .collison::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.collison::before {
  left: -10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $purple;
  color: $purple;
  animation: dotCollisionBefore 2s infinite ease-in;
}

.collison::after {
  left: 10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $purple;
  color: $purple;
  animation: dotCollisionAfter 2s infinite ease-in;
  animation-delay: 1s;
}


.loader, .loader:before, .loader:after {
  display: inline-block;
  width: 100%;
  height: 10px;
  position: absolute;
  z-index: 100000;
  animation: loading 4s cubic-bezier(.1,.85,.9,.15) infinite, loading-opacity 2s ease-in-out infinite alternate;
  background: linear-gradient(to right, $purple 0px, $purple 10px, transparent 10px)  no-repeat 0px 0px / 10px 10px;
  content: ' ';
}
.loader {
  animation-delay: .1s;
}
.loader:after {
  animation-delay: .2s;
}

.loader.more:before, .loader.more:after {
  content: ' ■';
  color: $purple;
  top: 0;
  line-height: 6px;
  font-size: 22px;
  vertical-align: top;
  animation: loading 4s cubic-bezier(.1,.85,.9,.15) infinite, loading-font 4s cubic-bezier(.1,.85,.9,.15) infinite !important;
}
.loader.more:before {
  animation-delay: 0s,.2s !important;
}
.loader.more {
  overflow: hidden;
  opacity: 0;
  animation: loading 4s cubic-bezier(.1,.85,.9,.15) infinite, loading-opacity 2s ease-in-out infinite alternate;
  animation-delay: .6s,.4s !important;
}
.loader.more:after {
  animation-delay: .4s,.8s !important;
}


.wave {
	position:relative;
	width:fit-content;
	height:fit-content;
	.dot {
		display:inline-block;
		width:1.2rem;
		height:1.2rem;
		border-radius:50%;
		margin-right:3px;
		background:#559;
		animation: wave 1.3s linear infinite;

		&:nth-child(2) {
			animation-delay: -1.1s;
		}

		&:nth-child(3) {
			animation-delay: -0.9s;
		}
	}
}

