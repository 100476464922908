@import "../../sass/variables";
@import "../../sass/mixins";

@mixin common {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $border;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: .25rem;
    height: 3.5rem;
    border: none;
    cursor: pointer;
    color: white;
    border: .1rem solid rgba($border, .1);
    font-size: 1.45rem;
}

.primarybutton{
    @include common;
    background: $primary;
    color: white;

    

    &:hover{
        border: .1rem solid darken(rgba($border, .5), 5%);
        background: rgb(21, 72, 182);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    @include respond(tab-port){
        height: 4.5rem;
        font-size: 1.4rem       
    }
};

.secondarybutton{
    @include common;
    background: transparent;
    color: $crimson;
    border: $crimson solid .1rem;
}

.group{
    display: flex;
    flex-direction: row;
    // padding: 0 2rem;
    margin-top: .25rem;
    width: 100%;
    justify-content: space-between;

    @include respond(tab-port){
        display: grid;
        grid-gap: .5%;
        grid-template-columns: repeat(3, 33%);
    }

    &_item{
        cursor: pointer;
        width: 13.5%;
        border-radius: .2rem;
        height: 3.5rem;
        display: flex;
        transition: all .25s ease;
        justify-content: center;
        align-items: center;
        border: .2rem solid #aaaaff55;
    
        @include respond(tab-port){
            width: 100%;
        }
    }
}

.selected{
    border-color: $border;
    background: $primary;
    color: white !important;
}